import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import LoginForm from './loginForm';
import {  Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import "../App.css"

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    width: "100vw",
    fontFamily: 'Raleway',
    // background: 'url("/leftBackground.png") no-repeat',
    backgroundImage: 'url("/leftBackground.png") , url("/rightBackground.png")',
    backgroundPosition: "left , right",
    backgroundRepeat: "no-repeat , no-repeat",
    backgroundSize: "60% 100% , 40% 100%",
    backgroundColor: "#f7f7f7",
    // background: 'url("/ncia2.jpg") no-repeat',
  
  },
  // contentWraper: {
  //   display: 'flex',
  //   // alignItems: 'center',
  //   justifyContent: 'center',
  // }
}));

const customColor = '#29A4CF';

const buttonStyle = {
  backgroundColor: customColor,
  color: 'white',
  fontFamily: 'Raleway',

};
const buttonStyle2 = {
  backgroundColor: 'white',
  color: 'black',
  fontFamily: 'Raleway',

};

function Landing() {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState('asset');

const {isSuccess,isLoading,isError}=useSelector((state)=>state.auth)
console.log("all states",isSuccess,isLoading,isError)
  const toggleLogin = (id) => {
    setActiveTab(id);
  };

  return (
    <div className={classes.root}>
      <div>
      <div className={classes.background}></div>
      <Grid >
        <Grid container justifyContent="center">
          <Grid className="contentWraper" item>
            {/* <div className={classes.loginLogo}> */}
            <div className='logoCnotent' style={{
                  width: '30rem',
                  textAlign: "center",
                  marginTop: "50px",
                  marginLeft: "50px"
                }}>
              <img
                src="/tnb.png"
                alt="Tnb"
                style={{
                  width: '110px',
                }}
              />
              <h3 style={{
                  color: "#114585",
                  fontWeight: "500",
                  fontSize: "22px",
                  fontFamily: 'Raleway',
                }}>
              TNB Energy Services Sdn Bhd
              </h3>
              <h3 style={{
                  color: "#114585",
                  fontWeight: "500",
                  fontSize: "30px",
                  fontFamily: 'Voces',
                }}>
              Integrated Asset & <br /> Facility Management System
              </h3>
              </div>
            {/* </div> */}
            <div >
            <Paper sx={{ borderRadius: "22px"}} className='loginPaper' >
              <Grid sx={{ width : "100%" }} container alignItems="center" justifyContent="space-between" paddingBottom={6}>
                <Grid item sx={{ width : "50%" }}>
                  <Button style={activeTab === 'asset' ? buttonStyle : buttonStyle2} 
                    sx={{   textTransform: "capitalize", padding: "15px" ,  boxShadow: "none", textAlign : "center" , width: "100%" , borderRadius: "0" ,borderTopLeftRadius : "22px",   }}
                    variant={activeTab === 'asset' ? 'contained' : 'none'}
                    onClick={() => toggleLogin('asset')}
                  >
                    <Typography variant='h6' sx={{ color: activeTab === 'asset' ? 'white' : 'primary',fontWeight:500 }}>Asset</Typography>
                  </Button>
                </Grid>
                <Grid item sx={{ width : "50%" }}>
                  <Button style={activeTab === 'project' ? buttonStyle : buttonStyle2} 
                  sx={{textTransform: "capitalize", padding: "15px" , textAlign : "center" , width: "100%" , borderRadius: "0" , borderTopRightRadius : "22px" }}
                    variant={activeTab === 'project' ? 'contained' : 'none'}
                    onClick={() => toggleLogin('project')}
                  >
                    <Typography  variant='h6'>Project</Typography>
                  </Button>
                </Grid>
              </Grid>
              <Grid container style={{width :' 100%'}} alignItems="center" flexDirection="column">
                <Grid item>
              <Typography sx={{ mb: 2  }} variant="h5" >
                Login to <span style={{ textTransform: "capitalize"}}>{activeTab} </span> 
              </Typography>
                </Grid>
                <Grid sx={{p:3 , maxWidth: "100%"}} item>
              {activeTab === 'asset' ? <LoginForm type="asset" title="Login" /> : <LoginForm type="project" title="Login" />}
                </Grid>
              </Grid>
            </Paper>
          <Grid container direction={'column'} alignItems={'center'} className='powered-by' sx={{ mt: 2, justifyContent: 'center' , marginLeft : "100px" , width:"60%"}}>
          <Grid item>
            <Typography sx={{   fontFamily: 'Raleway', color: '#000' }}>Powered by FOX</Typography>
          </Grid>
          <Grid item>
            <Typography sx={{  fontFamily: 'Raleway', color: '#000' }}>&#169; Infinity Wave Sdn Bhd</Typography>
          </Grid>
        </Grid>
          </div>
          </Grid>
        </Grid>
      </Grid>
      </div>
    </div>
  );
}

export default Landing;
