
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../redux/features/auth/authSilice';
import { Alert, Box, CircularProgress, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import { blue } from '@mui/material/colors';
import Grid from '@mui/material/Grid'
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    
  },
  input: {
    width: '100%',
    marginBottom: '10px',
  },

}));
const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(blue[100]),
  backgroundColor: blue[50],
  '&:hover': {
    backgroundColor: blue[100],
  },
}));

function LoginForm({ title, type }) {

  const buttonStyle = {
    textTransform: 'none',
    color: '#29A4CF',
  };

  let hrefValue = '';
  let hrefValueAsset = 'https://asset.ifmtnbes.com.my/forgot-password';
  let hrefValueProject = 'https://project.ifmtnbes.com.my/forgot-password';


  if (type === 'asset') {
    hrefValue = hrefValueAsset;
  }
  else {
    hrefValue = hrefValueProject;
  }

  const classes = useStyles();

  const dispatch = useDispatch();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isShown, setIsShown] = useState(false);

  const { isSuccess, isLoading, isError } = useSelector((state) => state.auth)
  // function displayToast() {
  //   return <Alert severity="error">This is an error alert — check it out!</Alert>
  // }
  const handleSubmit = async (e) => {
    console.log("submitting", username, password)
    if (!username || username === "" || !password || password === "") {
      console.log("here", isError)
      // { displayToast() }
    }
    else {
      e.preventDefault();
      if (type === 'project') {
        try {
          // Get the iframe element by its ID
          const iframe = document.getElementById("myIframe");
          const wind = iframe.contentWindow
          const getToken = async () => {
            const res = await dispatch(login({ username, password, type }))
            const data = {
              accessToken: res.payload.access,
              refreshToken: res.payload.refresh,
            }
            wind.postMessage(JSON.stringify(data), "*")
          }
          const answer = await getToken()
          console.log("answer", answer)
          window.location.href = "https://project.ifmtnbes.com.my"
        }
        catch (e) {
          console.log("what went wrong", e)
        }
      }
      if (type === 'asset') {
        try {
          const iframe = document.getElementById("myIframe-asset");
          const wind = iframe.contentWindow
          const getToken = async () => {
            const res = await dispatch(login({ username, password, type }))
            const data = {
              access: res.payload.access,
              refresh: res.payload.refresh,
            }
            wind.postMessage(JSON.stringify(data), "*")
          }
          await getToken()
          window.location.href = "https://asset.ifmtnbes.com.my"
        }
        catch (e) {
          console.log("what went wrong", e)
        }
      }
      else {
        console.log("not working")
      }

    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  return (
    <Grid sx={{ p: 1 , maxWidth: "100%" }}  justifyContent={'center'} alignItems={'center'}>
        {isError ? (
        <Alert sx={{ mb: 2 }} severity="error">
          Invalid credentials or server error!
        </Alert>
      ) : null}
      {isSuccess ? (
        <Alert sx={{ mb: 2 }} severity="success">
          Successful Login. Hold on tight, redirecting
        </Alert>
      ) : null}
      <iframe
        title="myIframe"
        src="https://project.ifmtnbes.com.my/login"
        width="800"
        height="600"
        id="myIframe"
        frameBorder="0"
        style={{ display: 'none' }}
      ></iframe>
      <iframe
        title="myIframe-asset"
        src="https://asset.ifmtnbes.com.my/login"
        width="800"
        height="600"
        id="myIframe-asset"
        frameBorder="0"
        style={{ display: 'none' }}
      ></iframe>

  <Container  sx={{ p: 1 , maxWidth: "100%" }} onKeyPress={handleKeyPress}>
  <TextField
      sx={{
        '& .MuiInputBase-root': {
          borderRadius: '10px',
          border: 'none',
        },
        '&.MuiTextField-root': {
          borderRadius: '10px',
        },
        mb: 2,
        width: '100%',
        bgcolor: '#dce8f4',
      }}
      className={classes.input}
      InputProps={{
        disableUnderline: true,
      }}
      variant="outlined"  // Make sure the TextField is outlined
      placeholder="Username"
      onChange={(e) => setUsername(e.target.value)}
    />
        <TextField
          sx={{ width: "100%", bgcolor: "#dce8f4" , 
          '& .MuiInputBase-root': {
            borderRadius: '10px',
            border: 'none',
          },
          '&.MuiTextField-root': {
            borderRadius: '10px',
          }}}
          className={classes.input}
          placeholder="Password"
          type={isShown ? "text" : "password"}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setIsShown(!isShown)}>
                  {isShown ? (
                     <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Container>
      <Container sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography variant='body1' sx={{fontSize: "14px" , whiteSpace: 'nowrap' }}>Forgot your password?</Typography>
        <Button sx={{ p: 1 }} href={hrefValue}>
          <Typography style={buttonStyle} sx={{fontSize: "12px" , whiteSpace: 'nowrap' }} variant='h9'>Reset password</Typography>
        </Button>
      </Container>
      <Container sx={{  display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <ColorButton type="submit" variant='contained' sx={{ paddingRight: 3, paddingLeft: 3, mt: 3, fontWeight: 'bold' }} onClick={handleSubmit}>
      {isLoading ? <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box> : "Login"}
    </ColorButton>
  </Container>
    </Grid>
  );
}

export default LoginForm;
